import React, { useCallback, useRef, useState } from "react";
import "./App.css";
import { useDropzone } from "react-dropzone";
import Loader from "./Loader";
import { postFile, getStatus, getBriefResult, setImages } from "./sources";
import errorSvg from "./error.svg";
import demoOriginal from "./test_026_original.png";
import demoResult from "./test_026_visualize.png";

function App({ demo }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [operationId, setOperationId] = useState("");
  const [originalImage, setOriginalImage] = useState("");
  const [resultImage, setResultImage] = useState("");
  const onDrop = useCallback(async acceptedFiles => {
    console.log(acceptedFiles, "acceptedFiles");
    try {
      if (acceptedFiles.length > 1)
        throw new Error("количество загружаемых файлов больше 1");
      setLoading(true);
      setError(null);
      if (!acceptedFiles[0]) {
        throw new Error("невалидный файл");
      }
      let response = await postFile(acceptedFiles[0]);
      const { operationId } = response.data;
      await getStatus(operationId);
      await setImages(setOriginalImage, setResultImage, operationId);
      const result = await getBriefResult(operationId);
      setResult(result.data);
      setOperationId(operationId);
    } catch (e) {
      console.log("Ошибка", e);
      if (e.message && e.message.includes("невалидный файл"))
        setError(e.message);
      else setError("не удалось обработать загруженное исследование");
    } finally {
      setLoading(false);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ".svs, .tiff, .tif, .zip"
  });

  const handleOpen = e => {
    e.stopPropagation();
    open(e);
  };
  if (demo) {
    return (
      <div className="App">
        <div className="app-content result">
          <p>
            Заключение: Выявлены патологические изменения. Вероятность 100 %.
          </p>
          <>
            <div className="app-content-images">
              <div className="app-content-images-content">
                <>
                  <img alt="" src={demoOriginal} />
                  <p>Оригинал</p>
                </>
              </div>
              <div className="app-content-images-content">
                <>
                  <img alt="" src={demoResult} />
                  <p>Результат</p>
                </>
              </div>
            </div>
          </>
        </div>
      </div>
    );
  }
  const handleReset = () => {
    setResult(null);
    setError(null);
  };

  if (result) {
    return (
      <div className="App">
        <div className="app-content result">
          {result && (
            <>
              <p>
                Заключение:
                {result.isPredicted
                  ? ` Выявлены патологические изменения. Вероятность ${Math.floor(
                      result.probability * 100
                    )}%`
                  : " Норма. Патологических изменений не выявлено"}
                .
              </p>
              <div className="app-content-images">
                <div className="app-content-images-content">
                  {originalImage ? (
                    <>
                      <img alt="" src={originalImage} />
                      <p>Оригинал</p>
                    </>
                  ) : (
                    <>
                      <img alt="" src={errorSvg} />
                      <p>Оригинал</p>
                    </>
                  )}
                </div>
                {result.isPredicted && (
                  <div className="app-content-images-content">
                    {resultImage ? (
                      <>
                        <img alt="" src={resultImage} />
                        <p>Результат</p>
                      </>
                    ) : (
                      !result.probability && (
                        <>
                          <img alt="" src={errorSvg} />
                          <p>Результат</p>
                        </>
                      )
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          {error && <p>Произошла ошибка: {error}</p>}
          <br />
          <button onClick={handleReset}>Загрузить снова</button>
        </div>
      </div>
    );
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="App">
      <div {...getRootProps()} className="app-content drop-area">
        <div className="drop-area__description">
          <input {...getInputProps()} />
          {error && <p>Ошибка: {error}</p>}
          {!error && isDragActive && (
            <p>Поместите файл в область для начала загрузки</p>
          )}
          {!error && !isDragActive && (
            <p>Перетащите файл или архив или нажмите кнопку "Загрузить"</p>
          )}
          <button onClick={handleOpen}>
            {error ? "Загрузить снова" : "Загрузить"}
          </button>
        </div>
      </div>
      <div className="warnings">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#48c8ff"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
        </svg>
        <div>
          <div className="warnings-title">
            Требования к загружаемым файлам исследования:
          </div>
          <ul>
            <li>
              файл исследования должен иметь формат .tif, .tiff, .svs или mrxs
            </li>
            <li>
              файл mrxs может быть обработан только при наличии сопровождающей
              папки и соблюдении следующих условий:
            </li>
            <ul>
              <li>файл mrxs и сопровождающая его папка имеют одинаковое имя</li>
              <li>
                файл mrxs и сопровождающая его папка запакованы в один .zip
                архив
              </li>
            </ul>
            <li>следует загружать только 1 файл или 1 архив за раз</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
