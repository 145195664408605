import axios from "axios";
import { TOKEN } from "./token";

// искусственная задержка в time мс
function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

const instance = axios.create({
  baseURL: "https://partners-http-api.celsus.ai/api/",
  headers: {
    Authorization: `Bearer ${TOKEN}`,
    maxContentLength: 999999999,
    maxBodyLength: 999999999,
  },
});

async function postFile(file) {
  var formData = new FormData();
  formData.append("file", file);
  return instance.post("/histopathology/research", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

async function getStatus(operationId) {
  return new Promise(async (resolve, reject) => {
    const MAX_TRIES = 1000;
    for (let i = 0; i <= MAX_TRIES; i++) {
      await delay(5000);
      let {
        data: { status, message },
      } = await instance.get(`/histopathology/research/${operationId}/status`);

      if (status === "Completed") {
        resolve();
        return;
      } else if (status === "Error") {
        reject(message);
        return;
      }
    }
    reject("Истекло время обработки, попробуйте заново...");
  });
}

async function getBriefResult(operationId) {
  return instance.get(`/histopathology/research/${operationId}/briefResults`);
}

async function setImages(setOriginalImage, setResultImage, operationId) {
  setOriginalImage(
    `${instance.defaults.baseURL}histopathology/research/${operationId}/originalImage`
  );
  setResultImage(
    `${instance.defaults.baseURL}histopathology/research/${operationId}/resultImage`
  );
}

export { setImages, postFile, getStatus, getBriefResult };
